.container {
  flex: 1
}

.profileContent {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 992px) {
  .profileContent {
    flex-direction: row;
  }
}