.lessonCard {
  display: flex;
  text-decoration: none;
  border: 1px solid #eaebeb;
  border-radius: 8px;
  flex-direction: row;
  background: #fff;
  box-sizing: border-box;
  color: #4d4d4d;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

.lessonCard:hover {
  background: hsl(0, 0%, 98%);
}

.innerProgressContainer {
  background-color: white;
  border-radius: 100%;
  height: fit-content;
  width: fit-content;
  box-shadow: 0px 4px 20px 0px #0e25171a;
  position: relative;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  color: rgba(14, 37, 23, 1);
  max-height: 4.5rem; /* Adjust based on line-height and font-size */
  overflow: hidden;
  flex: 1;
}

.author {
  font-weight: 400;
  font-size: 12px;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 7px;
}

.imageContainer {
  height: 100%;
  width: 30%;
}

.imgElem {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem 0 0 0.5rem;
  object-fit: cover;
}

.image {
  height: 100%;
  position: relative;
}

.leftContainer {
  display: flex;
  margin-left: 11px;
  gap: 8px;
}

.editIcon,
.lessonDeleteIcon {
  padding: 0 10px;
  background-color: white;
  border-radius: 100%;
  transition: color 0.1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editIcon:hover {
  color: #29834d;
}

.lessonDeleteIcon:hover {
  color: #ff0303;
}

.rightIconsContainer {
  margin-right: 11px;
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
}

.bookmark {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  gap: 7px;
  background-color: white;
  border-radius: 15px;
  align-items: center;
  position: absolute;
  top: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.bookmark:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  .bookmarkIcon {
    color: #29834d;
  }
}

.bookmarkIcon {
  margin-left: 0px;
}

.share {
  padding: 8px;
  background-color: white;
  border-radius: 100%;
}

.share:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.mobileBookmark {
  display: none;
}

.mobileShare {
  display: none;
}

.lessonCard:hover {
  .mobileShare {
    display: block;
  }
}
.share {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  box-shadow: 0px 4px 20px 0px #0e25171a;
  box-shadow: 0px 1px 10px 0px #38945c14;
}

.lessonText {
  flex: 1;
  padding: 15px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  border-top: 0;
  position: relative;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-left: 0;
}
