.cardContainer {
  padding: 24px 13px 24px 16px;
  gap: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
}

.cardContainer:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cardIcon {
  height: 18px;
  width: 18px;
  background: #f3fbf7;
  color: #29834d;
  border-radius: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTitle {
  font-family: "CircularStd";
  font-size: 16px;
  font-weight: 450;
  line-height: 21.6px;
}

.cardBody {
  font-family: "CircularStd";
  font-size: 12px;
  font-weight: 450;
  line-height: 16.8px;
  text-align: left;
  color: #979b99;
  margin-top: 6px;
}
