/* Extra small devices (phones, 600px and down) */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  margin-bottom: 30px;
  gap: 9px 8px;
}

@media only screen and (min-width: 600px) {
  .container {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}

/* Large devices (laptops/desktops, 1025px and up)  adjusted for ipad pro*/
@media only screen and (min-width: 1025px) {
  .container {
    gap: 9px 12px;
  }
}
