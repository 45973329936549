body {
  overflow-y: hidden;
}

button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
}

input {
  border: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.containerMobile {
  display: none;
}

.containerMobile {
  display: none;
}

.lessonContainer {
  flex: 1;
  overflow: auto;
  width: 100%;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #1d201f;
}

*::-webkit-scrollbar-thumb {
  background: #888;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.lessonContent {
  display: flex;
  height: calc(100% - 70px);
  width: 100%;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #313534;
  padding: 1rem;
  font-family: CircularStd, sans-serif;
}

.topbarTitle {
  font-size: 1.2rem;
  color: #ffffff;
}

.topbarButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0.5rem 0.8rem;
  background-color: #222524;
  border-radius: 6px;
  color: #0bda5e;
  font-size: 0.9rem;
}

.pageTitle {
  margin: 36px 1rem;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 25px;
}

.sectionWrapper {
  display: flex;
  /* width: 98%; */
  padding: 0 1rem;
  width: 100%;
  box-sizing: border-box;
}

.section {
  margin-bottom: 20px;
  width: 100%;
}

.addContent {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  padding-bottom: 60px;
}

.addContent .icon {
  font-size: 48px;
  color: #666666;
}

.savePage {
  position: fixed;
  bottom: 80px;
  left: 40px;
}

.savePage .savePageButton {
  border-radius: 100%;
  background-color: #39a76b;
  color: #fcfcfc;
  padding: 15px 20px;
  font-size: 30px;
}

.icon {
  font-size: 1.125rem;
  color: #666666;
}

.errorContainer {
  position: absolute;
  width: 80%;
  max-width: 600px;
  top: 20px;
  right: 20px;
}

.continueGeneratingSection {
  color: #f2f2f2;
  border-radius: 20px;
  background: #1d201f;
  margin: 40px auto;
  font-family: "CircularStd";
  font-size: 14px;
  font-weight: 450;
  line-height: 24px;
  text-align: center;
  width: 70%;
  align-items: center;
  position: relative;
}

.popOption {
  display: flex;
  position: relative;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}

.innerContainer,
.inputContainer {
  display: flex;
  height: 100%;
}

.innerFLUPContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 30px 10px;
}

.continueGeneratingSectionChat {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin: 20px 0;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.inputTopic {
  font-family: "CircularStd";
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  text-align: center;
  align-self: center;
}

.options {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.option {
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.option:hover {
  color: #39a76b;
}

.regenPromptInput {
  width: 80%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px;
  border: 2px solid #eaeaea;
}

.inputContainer {
  display: flex;
  height: 100%;
  margin-top: 10px;
}

.submit {
  align-content: center;
  background-color: #39a76b;
  color: white;
  padding: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}

.backIcon {
  padding: 10px;
  cursor: pointer;
}

.backIcon:hover {
  background-color: #161817;
}

.backHeader {
  display: flex;
}

.contextMenu {
  position: absolute;
  background-color: #222524;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 6;
  top: -180px;
  left: 0px;
  width: fit-content;
  padding-right: 15px;
}

.regenTitle {
  text-align: left;
  color: #767f7a !important;
  cursor: default;
  margin-left: 15px;
  margin-top: 10px;
  text-wrap: nowrap;
}

.list {
  box-sizing: border-box;
  padding: 1px;
  margin: 0;
  list-style: none;
}

.list .item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 9px 0 9px 9px;
  margin: 5px;
  color: #fff;
  width: 100%;
}

.iconB {
  color: #0bda5e;
}

.list .item:hover {
  cursor: pointer;
  background-color: #428ce6;
}

@media only screen and (max-width: 600px) {
  
  .continueGeneratingSection {
    width: 98%;
  }

  .innerContainer {
    padding: 20px 10px;
  }

  .contentTypePopOverContainer {
    width: 95%;
  }

  .savePage {
    left: 20px;
  }
}
