.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: solid;
  border-top-width: 1px;
  border-top-color: gainsboro;
  padding: 40px 20px;
  font-size: 1rem;
  background-color: hsl(0, 0%, 95%);
}

.link {
  display: none;
  color: hsl(210, 100%, 40%);
  padding: 0px 5px 0px 5px;
  text-decoration: none;
  font-weight: 500;
}

.link:visited {
  color: hsl(210, 100%, 40%);
}

@media (min-width: 576px) {
  .link {
    display: inline-block;
    color: hsl(210, 100%, 40%);
    padding: 0px 5px 0px 5px;
    text-decoration: none;
    font-weight: 500;
  }
}
@media only screen and (min-width: 1025px) {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: solid;
    border-top-width: 1px;
    border-top-color: gainsboro;
    padding: 40px 70px 40px 70px;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    background-color: hsl(0, 0%, 95%);
  }
}
