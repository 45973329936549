.mobile {
  display: block;
  height: 180px;
}

.desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  .desktop {
    display: block;
    min-height: 330px;
    height: 100%;
  }

  .mobile {
    display: none;
  }
}
