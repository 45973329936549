.container {
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 30px 4% 10px 4%;
}

.spacing {
  height: 20px;
}

.signupContainer {
  color: rgb(31, 31, 31);
  font-size: 0.9rem;
  font-weight: 500;
}

.svg {
  fill: hsl(147, 49%, 44%);
}

.logo {
  height: 40px;
}

@media (min-width: 576px) {
  .container {
    padding: 30px 70px 0px 70px;
  }

  .logo {
    height: 60px;
  }
}

@media only screen and (min-width: 1025px) {
}
