.container {
  margin-top: -65px;
  width: 100%;
}

.lessons {
  margin-left: auto;
  width: 100%;
  margin-right: 30px;
}

.userDetails {
  width: 100%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userSubscription {
  border: 2px solid #39a76b;
  border-radius: 16px;
  width: fit-content;
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.userSubscription:hover {
  /* border: 2px solid #39a76b; */
  /* background-color: #39a76b; */
  opacity: 0.7;
}

.userName {
  font-size: 26px;
  font-weight: 500;
}

.lessonCount {
  font-size: 14px;
  font-weight: 450;
  color: #707572;
  margin-top: 5px;
}

.editProfileButton {
  border-radius: 100px;
  box-shadow: 0px 0px 0px 1px #29834d;
  color: #29834d;
  font-size: 14px;
  font-weight: 450;
  width: fit-content;
  padding: 10px 19px;
  margin-top: 24px;
  cursor: pointer;
}

.profileImage svg {
  position: absolute;
  bottom: -36px;
  right: -28px;
}

.profileImage {
  border: 5px solid #ffffff;
  border-radius: 12px;
  position: relative;
  height: 120px;
  width: 120px;

  /* Mobile only */
  margin-left: auto;
  margin-right: auto;
}

.profileImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.genericImage {
  background-color: #29834d;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 700;
  border-radius: inherit;
}

@media only screen and (min-width: 992px) {
  .container {
    width: 300px;
    padding-left: 40px;
    white-space: nowrap;
  }

  .profileImage {
    margin-left: 0;
    margin-right: 0;
  }

  .userDetails {
    width: fit-content;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
    align-items: flex-start;
  }
}
