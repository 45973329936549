.clarificationChatTitle > h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

.chatContainer {
  height: 100%;
  width: 100%;
  display: flex;
  background: "blue";
  flex-direction: column;
  justify-content: space-between;
}

.chatTopSection {
  display: "flex";
  flex-direction: "column";
  background: "red";
}

.clarificationChatTitle {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
  border-bottom: 1px solid #313534;
  gap: 10px;
  align-items: center;
  color: #fff;
  font-family: CircularStd, "Poppins", sans-serif;
  height: fit-content;
}

.clarificationChatTitle h2 {
  margin-left: 10px;
}

.chatMessageContainer {
  flex: 1;
  overflow: auto;
}

.sendIconDisabled {
  padding: 10px;
  cursor: not-allowed;
  background: #313533;
  color: #d5d7d6;
  border-radius: 22px;
  position: absolute;
  right: 0;
  height: 1rem;
  width: 2rem;
  font-size: 1rem;
  align-self: center;
}

.clarificationExamples {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 100%;
}

.clarificationExampleLogo {
  height: 70px;
  width: auto;
}

.clarificationExamplesList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
}

.clarificationExampleCard {
  flex: 0 1 31%;
  background-color: #161817;
  color: white;
  border: 1px solid #333;
  box-shadow: none;
}

.clarificationExampleCard .cardIcon {
  background: #000;
}

.messages {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow: auto;
}

.chatMessage {
  border-radius: 20px;
  max-width: 70%;
  box-sizing: border-box;
  padding: 10px;
  overflow-x: auto;
}

.escapeSection {
  justify-content: space-between;
  width: 100%;
  align-items: center;
  display: flex;
}

.backIcon {
  padding: 10px;
  border: 1px solid #39a76b;
  border-radius: 4px;
  font-weight: 600;
  background-color: transparent;
  color: white;
}

.backIcon:hover {
  padding: 10px;
  background-color: #39a76b;
  border-radius: 4px;
  color: white;
}

.aiChatMessage {
  margin-right: auto;
  margin-left: 10px;
  color: #f2f2f2;
  max-width: 90%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  gap: 12px;
}

.logo {
  margin-top: 12px;
  background-color: #1d201f;
  border-radius: 100%;
  height: fit-content;
  width: fit-content;
  display: flex;
  border: solid;
  border-width: 2px;
  border-color: #353535;
}

.aiBottomIcons {
  display: flex;
  gap: 6px;
  justify-content: space-between;
}

.textElem {
  justify-self: center;
  border: 1px solid #232424;
  border-radius: 18px;
  align-content: center;
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;
}

.textElem:hover {
  background-color: #1d201f;
}

.iconElem {
  padding: 10px 19px;
  border: 1px solid #232424;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
}

.iconElem:hover {
  background-color: #1d201f;
}

.aiMessageContent {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.userChatMessage {
  margin-left: auto;
  margin-right: 20px;
  background-color: #1d201f;
  color: #fff;
  font-size: 14px;
  font-weight: 450;
  text-align: left;
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.loader {
  height: 50px;
  width: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(#000 0 0);
  --r1: radial-gradient(farthest-side at bottom, #000 93%, #0000);
  --r2: radial-gradient(farthest-side at top, #000 93%, #0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2),
    var(--c), var(--r1), var(--r2);
  background-repeat: no-repeat;
  animation: l2 1s infinite alternate;
}
@keyframes l2 {
  0%,
  25% {
    background-size:
      8px 0,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(50% - 2px),
      0 calc(50% + 2px),
      50% 50%,
      50% calc(50% - 2px),
      50% calc(50% + 2px),
      100% 50%,
      100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  50% {
    background-size:
      8px 100%,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(0% - 2px),
      0 calc(100% + 2px),
      50% 50%,
      50% calc(50% - 2px),
      50% calc(50% + 2px),
      100% 50%,
      100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  75% {
    background-size:
      8px 100%,
      8px 4px,
      8px 4px,
      8px 100%,
      8px 4px,
      8px 4px,
      8px 0,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(0% - 2px),
      0 calc(100% + 2px),
      50% 50%,
      50% calc(0% - 2px),
      50% calc(100% + 2px),
      100% 50%,
      100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  95%,
  100% {
    background-size:
      8px 100%,
      8px 4px,
      8px 4px,
      8px 100%,
      8px 4px,
      8px 4px,
      8px 100%,
      8px 4px,
      8px 4px;
    background-position:
      0 50%,
      0 calc(0% - 2px),
      0 calc(100% + 2px),
      50% 50%,
      50% calc(0% - 2px),
      50% calc(100% + 2px),
      100% 50%,
      100% calc(0% - 2px),
      100% calc(100% + 2px);
  }
}


@media only screen and (max-width: 600px) {
  .aiMessageContent {
    max-width: 95%;
  }

  .clarificationExamples {
    gap: 20px;
  }

  .clarificationExampleCard {
    flex: 0 1 100%;
  }
}