.editorToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 1rem;
  border-bottom: 1px solid #313534;
}

.editorToolbar h2 {
  font-size: 1.2rem;
}

.editorToolbarButtons {
  display: flex;
  gap: 0px;
}

.editor {
  height: calc(100% - 88.86px) !important;
}

.noFileState {
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.noFileStateText {
  font-size: 1.2em;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
}


@media screen and (max-width: 1025px) {
  .editorToolbarWebButton {
    display: none;
  }
}