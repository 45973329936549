.container {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}

.mainWindow {
    display: flex;
    padding: 40px;
    margin: auto;
    gap: 40px;
    min-height: 71dvh;
    justify-items: center

}

.outline {
    display: flex;
}

.promptSection {
    flex: 1;
    font-size: 20px;
    align-content: center;
}

.generatedOutline {
    flex: 1;
    align-content: center;
}

.outlineTitle {
    font-weight: 800;
    font-size: 20px;
}

.outlines {
    margin: 30px 0;
    max-height: 40vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.outline {
    display: flex;
    gap: 10px;
    
}

.generateButton {
    background-color: #39a76b;
    color: white;
    padding: 10px;
    border-radius: 4px;
    font-weight: 600;
}