.sideBarContainer {
  align-self: stretch;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #0f1010;
  transition: all 0.3s linear;
  height: 100%;
}

.sideTab {
  border-right: 1px solid #313534;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0.75rem;
  box-sizing: border-box;
  gap: 1.2rem;
}

.tab {
  cursor: pointer;
  height: fit-content;
  padding: 5px;
  border: 1.5px solid #1d201f;
  color: #aaa;
  border-radius: 5px;
}

.activeTab {
  border: 1px solid #cacecc;
  cursor: pointer;
  height: fit-content;
  padding: 5px;
  color: #cacecc;
  background-color: #313534;
  border-radius: 5px;
  border: 1.5px solid #4b4e4d;
}

.tab:hover {
  background-color: #222624;
}

.sideBar.isWorkspaceHighlighted {
  position: relative;
  z-index: 9999;
  margin-top: 4%;
  margin-left: 1.5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.sideBar.isWorkspaceHighlighted .textInputBottomBar {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: bold;
  color: #333;
}

.sideBar {
  min-width: 200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  height: 100%;
}

.sideBar::-webkit-scrollbar {
  width: 10px;
}

.sideBar::-webkit-scrollbar-thumb {
  background: #dfe2e0;
}

@media screen and (max-width: 1025px) {
  .sideBar {
    width: 100%;
  }

  .sideBarContainer {
    /* transition: none; */
  }
}