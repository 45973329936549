.container {
  width: 100%;
  max-width: 700px;
  height: 80vh;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  border-radius: 20px;
}

.contentDiv {
  padding: 1.5rem 1rem;
  box-sizing: border-box;
  overflow: auto;
  background: white;
  border-radius: 20px;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(158, 235, 235, 0.6) -4.58%,
    rgba(213, 234, 153, 0.6) 5.06%,
    white 15%
  );
}

.modalCloseButton {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 1.5rem;
  color: #0e2517;
}

.contentDiv .iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.contentDiv .iconContainer .icon {
  font-size: 2rem;
  color: white;
  padding: 15px;
  border: 3px solid rgba(102, 204, 141, 1);
  border-radius: 18px;
  background: linear-gradient(180deg, #272a28 -9.85%, #235c3a 70.31%);
  box-shadow:
    0px 10px 20px 0px rgba(182, 234, 197, 1),
    0px 10px 20px 0px rgba(138, 153, 51, 0.1);
}

.contentDiv h2 {
  color: rgba(11, 30, 18, 1);
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 10px;
}

.contentDiv p {
  font-size: 0.9rem;
  color: hsl(0, 0%, 40%);
  font-weight: 500;
  margin: 0;
  margin-bottom: 40px;
}

.content {
  font-size: 0.9rem;
  font-weight: 450;
  color: hsl(0, 0%, 40%);
  line-height: 1.7;
  margin-bottom: 25px;
}

.content label {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 5px 0;
  display: block;
}

.content .titleInput {
  width: 100%;
  height: 50px;
  border: 1px solid gainsboro;
  background-color: white;
  border-radius: 30px;
  padding: 0 10px;
  box-sizing: border-box;
}

.contentDiv .thumbnailPreview {
  width: 100%;
  height: 300px;
  background: white;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.contentDiv .thumbnailPreview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.createCourseButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: solid;
  border-width: 1px;
  border-radius: 30px;
  margin-bottom: 15px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-sizing: border-box;
  background: #29834d;
}

.createCourseButton:hover {
  cursor: pointer;
}

.createCourseButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (min-width: 768px) {
  .contentDiv {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .modalCloseButton {
    display: none;
  }
}
