.container {
  display: flex;
  flex-direction: column;
  background-color: #161817;
  color: #e4e7e5;
  height: 100%;
  width: 100%;
  flex: auto;
  justify-content: center;
  align-items: center;
}

.containerWhenActive {
  background-color: white;
}

.frame {
  border: none;
}

.header {
  display: flex;
  padding: 20px;
  margin-top: 1rem;
  flex: 1;
}

.browserContainer {
  height: calc(100% - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.editorToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 1rem;
  border-bottom: 1px solid #313534;
}

.editorToolbar h2 {
  font-size: 1.2rem;
}

.editorToolbarButtons {
  display: flex;
  gap: 0px;
}

.urlBar {
  background-color: #222524;
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;
  flex: 1;
}

.urlText {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #e4e7e5;
}

.enterPortContainer {
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.browserIcon {
  background-color: #222524;
  border-radius: 100%;
  color: #cacecc;
  padding: 10px;
}

.portInputContainer {
  justify-content: center;
}

.portInput {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 5px;
  flex: 1;
  width: 50px;
  color: #e4e7e5;
  text-align: center;
}

.portInput:focus {
  outline: none;
}

.portInputLarge {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: #e4e7e5;
  border-radius: 3px;
  padding: 5px;
  flex: 1;
  width: 80px;
  text-align: center;
}

.portInputLarge:focus {
  outline: none;
}

@media screen and (max-width: 1025px) {
  .editorToobarIdeButton {
    display: none;
  }

  .urlText {
    font-size: 0.75rem;
  }

  .enterPortContainer {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 720px) {
  .header {
    padding: 10px 0;
  }
}


