.container {
  display: none;
  z-index: 100;
  position: fixed;
  top: 70px;
  width: 100%;
  height: 50%;
  background: hsl(0, 0%, 99%);
}

.active {
  background: #f2f3f2;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    border-top: 1px solid gainsboro;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    width: 256px;
    height: calc(100% - 70px);
    padding: 40px 0;
    background: #f8fafb;
    box-sizing: border-box;
    border-right: 1px solid gainsboro;
  }
  .buttonContainer {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 500;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 80%;
    padding: 15px 10px;
    outline: none;
    line-height: 16px;
    margin: 10px auto;
    border-radius: 6px;
  }

  .buttonRed .buttonText {
    color: #fb2047 !important;
  }

  .buttonRed .buttonIcon {
    color: #fb2047 !important;
  }

  .buttonIcon {
    color: hsl(0, 0%, 60%);
    font-size: 1rem;
  }

  .button .buttonText {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    color: hsl(0, 0%, 50%);
    line-height: 1rem;
  }

  .active .buttonText {
    background: #f2f3f2;
    color: black;
  }

  .active .buttonIcon {
    background: #f2f3f2;
    color: black;
  }

  /* .button:hover {
    background: hsl(0, 0%, 88%);
    cursor: pointer;
  } */

  /* .button:hover .buttonText {
    color: hsl(147, 52%, 46%);
  } */

  /* .button:hover i {
    color: hsl(147, 52%, 46%);
  } */
}
