body {
  overflow-y: hidden;
}

button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
}

input {
  border: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.containerMobile {
  display: none;
}

.containerMobile {
  display: none;
}

.pageBuilderContainer {
  flex: 1;
  overflow: auto;
}

.pageTitle {
  margin: 36px 5%;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  width: 90%;
}

.pageTitle input {
  color: #39a76b;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  background-color: transparent;
  font-family: CircularStd;
  width: 100%;
}

.pageTitle input::placeholder {
  color: #39a76b;
}

.pageTitle input:focus {
  outline: 0;
}

.addContent {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  padding-bottom: 60px;
}

.addContent .icon {
  font-size: 48px;
  color: #666666;
}

.savePage {
  position: fixed;
  bottom: 80px;
  left: 40px;
}

.savePage .savePageButton {
  border-radius: 100%;
  background-color: #39a76b;
  color: #fcfcfc;
  padding: 15px 20px;
  font-size: 30px;
}

.icon {
  font-size: 1.125rem;
  color: #666666;
}

.errorContainer {
  position: absolute;
  width: 80%;
  max-width: 600px;
  top: 20px;
  right: 20px;
}

@media only screen and (max-width: 600px) {
  .contentTypePopOverContainer {
    width: 95%;
  }

  .savePage {
    left: 20px;
  }
}
