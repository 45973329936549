.messageBox {
    border: 1px solid #1d201f;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: #1b1d1c;
    min-height: 40px;
    border: 1px solid #313533;
    border-radius: 1.5rem;
    margin: 1rem;
    display: flex;
    box-sizing: border-box;
}

.messageInput {
    background-color: transparent;
    border: none;
    width: calc(100% - 3rem);
    outline: none;
    color: #fff;
    padding: 12px 1rem;
    box-sizing: border-box;
    resize: none;
    font-family: CircularStd, "Poppins", sans-serif;
    font-size: 1rem;
    max-height: 400px;
}

.sendButton:disabled {
    cursor: not-allowed;
    background: #f5f5f5;
    color: #d5d7d6;
}

.sendButton {
    padding: 10px 1.5rem;
    box-sizing: border-box;
    margin-right: 2px;
    background-color: #29834d;
    color: white;
    border-radius: 22px;
    cursor: pointer;
    font-size: 1rem;
}

.sendIcon {
    /* position: absolute;
    right: 0;
    width: 2rem; */
}

