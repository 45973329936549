.lessonEditButton {
  padding: 10px 15px;
  box-sizing: border-box;
  background: transparent;
  font-weight: 700;
  background-color: #39a76b;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: white;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  text-decoration: none;
  text-align: center;
  margin: auto 0.5rem;
  height: fit-content;
}
