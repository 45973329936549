.sectionEditWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
  margin-top: 40px;
}

.sectionEditIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  width: 50px;
  box-sizing: border-box;
}

.sectionEditIcons .icon {
  font-size: 1.125rem;
  margin: 8px 0;
  padding: 0;
  color: black;
  position: relative;
}

.sectionEditIcons .sectionDeleteIcon {
  color: #ff0303;
}

.sectionTypePopOverContainer {
  position: absolute;
  width: 500px;
  height: 100%;
  right: 0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionTypePopOver {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #eee;
}

.sectionTypePopOver .sectionTypeIcons {
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
}

.sectionTypePopOverPointer {
  position: relative;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
}

.sectionTypeIcons {
  width: calc(100% - 50px);
  display: flex;
  flex-shrink: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.sectionTypeIcons .icon {
  font-size: 25px;
  background-color: rgba(89, 164, 112, 0.09);
  color: #39a76b;
  padding: 20px 8%;
  border-radius: 10px;
}

.sectionTypeIcons .icon:hover {
  background-color: rgba(89, 164, 112, 0.2);
}

.sectionTypeIcons .icon.disabled {
  background-color: rgba(89, 164, 112, 0.09);
}

.sectionTypeIcons .icon.disabled:hover {
  background-color: rgba(89, 164, 112, 0.09);
}

.sectionTypeIcons .activeIcon {
  color: white;
  background-color: #39a76b;
}

.videoEditor {
  width: 100%;
  box-sizing: border-box;
}

.videoEditor input {
  width: 100%;
  outline: none;
  background-color: #f8f8f8;
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box;
}

.videoEditor input::placeholder {
  color: #aaa;
}

.uploadVideoContainer {
  width: 100%;
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
}

.uploadVideoContainer span {
  position: relative;
  display: inline-block;
  width: 20%;
  top: -10px;
  background-color: #fcfcfc;
  color: #8e8e8e;
  font-weight: 600;
  font-size: 1.25rem;
}

.uploadVideo {
  background-color: #f8f8f8;
  border-radius: 10px;
  font-size: 1.25rem;
  color: #aaa;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.practiceQuestions {
  width: 100%;
}

.practiceQuestions h2 {
  margin: 0 0 20px 0;
}

@media only screen and (max-width: 600px) {
  .sectionTypePopOverContainer {
    position: absolute;
    max-width: 320px;
    height: 100%;
    right: 0px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sectionTypePopOver {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 4px;
    overflow-x: auto;
    box-sizing: border-box;
  }

  .sectionTypeIcons {
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .sectionTypeIcons .icon {
    margin: 4px;
    padding: 20px 8%;
    font-size: 14px;
    box-sizing: border-box;
  }
}
.codeBlockEditor {
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  max-height: 300px;
  border: 1px solid black;
  font-family: "Courier Prime";
}

.codeBlockEditor * {
  font-family: "Courier Prime";
}

.codeBlockEditor .editor {
  width: 100%;
}
