.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: CircularStd, sans-serif;
    box-sizing: border-box;
    padding: 20px;
    color: white;
    width: 100%;
    gap: 40px;
    margin-top: 100px;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.loginButton {
    background-color: #29834D;
    color: white;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
}

.input {
    padding: 15px;
    box-sizing: border-box;
    height: auto;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    background-color: #2A2D2C;
    outline: 0;
    color: white;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    align-items: center;
}

.importButton, .disconnectButton {
    background-color: #222524;
    padding: 15px;
    border-radius: 20px;
    outline: 0;
    width: 100%;
    max-width: 500px;
}

.importButton {
    color: #0BDA5E;
}

.disconnectButton {
    color: #F84C41;
}