.mainParent {
    height: 100%;
    box-sizing: border-box;
}

.containerParent {
    height: 100%;
    display: flex;
    flex: 1;
}

.hidden {
    display: none;
}

.show {
    width: 100%;
}

.loginBlock {
    z-index: 2;
    display: flex;
    flex: 1;
    height: 100%;
    -moz-backdrop-filter: blur(5px);
    -o-backdrop-filter: blur(5px);
    -ms-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    justify-content: center;
    background-color: transparent;
}

.loginBlockContainer {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    color: #BEC1BF;
}

.loginButton {
    background-color: #39A76B;
    width: fit-content;
    padding: 10px;
    margin: auto;
    border-radius: 3px;
    color: white; 
    cursor: pointer;
    font-weight: 600;
}