.container {
  position: relative;
  border-radius: 0.25rem;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: none;
  background: hsl(0, 0%, 95%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: hsl(0, 0%, 50%);
}

.container:hover {
  background: hsl(0, 0%, 93%);
}

.plusIcon {
  color: hsl(0, 0%, 70%);
}

.subtitle {
  margin-top: 20px;
  display: block;
  font-size: 1rem;
  font-weight: 600;
}
