.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  height: -webkit-fill-available;
  width: 100vw;
}

.innerLoginDiv {
  height: 500px;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textComponent {
  width: 80%;
}

.sucessText {
  font-size: 1.5rem;
  font-weight: 500;
}

.signupButton {
  height: 40px;
  padding: 10px 15px 10px 15px;
  font-family: "Montserrat", sans-serif;
  background: transparent;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: gainsboro;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 15px;
  text-decoration: none;
  color: rgb(31, 31, 31);
}

.signupButtonLeadingText {
  display: none;
}

.textComponent {
  align-self: center;
  text-align: center;
  width: 50%;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 576px) {
  .container {
    height: 100vh;
    height: 100dvh;
  }
  .signupButtonLeadingText {
    display: inline-block;
  }
}
