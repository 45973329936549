.container {
  margin: 40px 10px;
}

.mainWindow {
  position: relative;
  top: 70px;
  margin-left: 0px;
  padding: 20px;
}

.gridTitle {
  font-size: 1.25em;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
  color: #0e2517;
}

@media only screen and (min-width: 992px) {
  .mainWindow {
    position: relative;
    margin-left: 256px;
    padding: 20px;
  }

  .container {
    margin-left: 25px;
    margin-right: 25px;
  }
}
