.container {
  display: flex;
  flex-direction: row;
  background: white;
  margin: 10px;
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  border-color: gainsboro;
  box-sizing: border-box;
}

.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 200;
  background: white;
  margin: -1px; /* Margin is - container border */
  color: hsl(352, 64%, 60%);
  border-left: solid;
  border-color: hsl(352, 64%, 60%);
  border-width: 20px;
  border-radius: 10px 0px 0px 10px;
}

.sideContainer i {
  display: none;
}

.mainContainer {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.errorPrefix {
  font-weight: 500;
  font-size: 1.2em;
  color: black;
  margin-bottom: 10px;
}

.errorMessage {
  display: block;
  font-size: 0.8em;
  font-weight: 400;
  color: darkgray;
}

.closeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  font-weight: 500;
  color: darkgray;
  border-left: solid;
  border-width: 1px;
  border-color: gainsboro;
  font-size: 0.8em;
}

.closeContainer:hover {
  cursor: pointer;
}

@media only screen and (min-width: 1025px) {
  .sideContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 200;
    background: hsl(352, 64%, 60%);
    padding: 0px;
    border: none;
    margin: -1px; /* Margin is - container border */
    color: white;
    width: 100px;
  }

  .sideContainer i {
    display: block;
  }

  .errorMessage {
    display: block;
    font-weight: 400;
    font-size: 1em;
    color: darkgray;
  }

  .closeContainer {
    font-size: 1em;
    width: 100px;
  }
}
