.contextMenu {
  position: absolute;
  width: 100px;
  background-color: #383838;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 6;
}

.list {
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
  list-style: none;
}

.list .item {
  padding: 9px 6px;
  color: #fff;
}

.list .item:hover {
  cursor: pointer;
  background-color: #428ce6;
}
