.container {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.mainWindow {
  position: relative;
  top: 70px;
  margin-left: 0px;
  padding-bottom: 60px;
  background: hsl(0, 0%, 99%);
}

@media only screen and (min-width: 992px) {
  .mainWindow {
    position: relative;
    margin-left: 260px;
    padding-bottom: 60px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100vh;
    height: 100dvh;
  }
}
