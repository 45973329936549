.container,
.desktopContainer {
  z-index: 8;
  position: fixed;
  background: hsl(0, 0%, 99%);
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.desktopContainerDark {
  background: #0F1010;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #313534;
}

.mobileContainer {
  display: none;
}

.titleBar {
  display: inline-block;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleBar:hover {
  cursor: pointer;
}

.titleBarTransparent {
  background: transparent;
}

.rightNav {
  display: flex;
  flex: 1;
  height: 100%;
}

.rightBar {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  color: green;
}

.userProfileSection {
  display: flex;
  gap: 12px;
  margin: auto 20px;
  align-items: center;
}

.desktopContainerDark .username {
  color: white;
}

.desktopContainerDark .userEmail {
  color: white;
}

.profileImage,
.profileImage img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  padding: 10px;
}

.notifications {
  height: 20px;
  width: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 4px 20px 0px #0e25171a;
  cursor: pointer;
}

.leftBar {
  margin-left: 30px;
  color: hsl(0, 0%, 30%);
  display: flex;
}

.welcome {
  font-size: 1.44rem;
  font-weight: 500;
  color: #0e2517;
  align-self: center;
}

.gradYear {
  font-weight: 600;
}

@media (max-width: 1000px) {
  .menuBars {
    background-color: #29834d;
    padding: 10px;
    color: white;
    border-radius: 6px;
  }

  .rightBar {
    gap: 12px;
  }

  .desktopContainer {
    display: none;
  }

  .notifications {
    border-width: 0px, 0px, 0px, 0px;
    border: 1px solid #dfe2e1;
  }

  .mobileContainer {
    position: fixed;
    background: hsl(0, 0%, 99%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 70px;
  }

  .mobileContainerDark {
    background: #0F1010;
  }

  .mobileMenuButton {
    font-size: 1.25rem;
    color: #333;
  }

  .mobileMenuOverlay {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100vh;
    height: 100dvh;
  }

  .mobileMenu {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom: 1px solid gainsboro;
  }

  .mobileContainerDark .mobileMenu {
    background: #0F1010;
    border-bottom: 1px solid #313534;
  }

  .mobileMenu a {
    margin: 10px 0;
    color: hsl(147, 49%, 44%);
    text-decoration: none;
    font-weight: 500;
  }

  .mobileMenu .logoutButton {
    color: red;
  }
}

@media only screen and (min-width: 1025px) {
  .titleBar {
    display: inline-block;
    height: 100%;
    display: flex;
  }

  .titleBarTransparent {
    background: transparent;
  }
}
