.button {
  margin: 0 6px;
  padding: 0.5rem 0.8rem;
  font-weight: 400;
  font-size: 0.9rem;
  border-radius: 6px;
  cursor: pointer;
  gap: 4px;
  color: #0bda5e;
  background-color: #222524;
  align-items: center;
}

.button:hover {
  background-color: hsl(147, 52%, 24%);
  color: white !important;
}
