.textFieldEditor {
  width: 100%;
  box-sizing: border-box;
}

.textFieldEditor div.quill {
  background-color: #f8f8f8;
  border-radius: 10px;
}

.textFieldEditor .ql-container {
  font-family: "Poppins", sans-serif;
}

.textFieldEditor .ql-toolbar.ql-snow {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #b5b5b5;
  padding: 10px;
}

.textFieldEditor .ql-container.ql-snow {
  border: 0;
}

.textFieldEditor .ql-editor {
  min-height: 200px;
}

.textFieldEditor .ql-snow .ql-editor pre.ql-syntax {
  font-size: 1rem;
  font-family: "Courier Prime", monospace;
}
