.container {
  display: flex;
  flex-direction: row;
  background-color: #161817;
  border-left: 1px solid #1d201f;
  color: #ffffff;
  height: 100%;
  width: auto;
  flex: 1;
}

.editorToolbar {
  display: flex;
  flex-direction: row-reverse;
  height: 40px;
}

.terminalSelect {
  cursor: pointer;
  margin: auto;
  padding: 5px;
}

.terminalSelect:hover {
  background-color: #161817;
}

.errorComponentContainer {
  position: absolute;
  right: 0;
  z-index: 20;
}
.runButton {
  margin: 5px 20px;
  padding: 5px 10px;
  border: 1.5px solid hsl(147, 52%, 24%);
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  color: #1b5a37 !important;
  align-items: center;
}

.runButton:hover {
  background-color: hsl(147, 52%, 24%);
  color: white !important;
}
.heading {
  position: relative;
  justify-content: space-between;
  display: flex;
  margin-right: 10px;
  background-color: #1b1d1c;
  padding: 0 1rem;
  box-sizing: border-box;
}

.headingContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.spinner {
  padding: 5px 8px;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.minimizeIcon {
  cursor: pointer;
  padding: 5px 8px;
  border: 1px solid #4b4e4c;
  background-color: #272a29;
  border-radius: 6px;
}

.minimizeIcon:hover {
  background-color: #161817;
}

.terminalContainerCollapsed {
  height: 70px;
  display: flex;
  flex-direction: column;
}

.rightIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 720px) {
  .sideBar {
    display: none;
  }

  .mobileWorkspaceSideBar {
    display: flex;
  }

  .sideBarTogglerContainer {
    background: hsl(0, 0%, 95%);
  }

  .sideBarToggler {
    padding: 10px;
  }

  .activeSidebarToggler {
    background: hsl(0, 0%, 70%);
    padding: 10px;
  }

  .textInputContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .textInputContainerCollapsed {
    flex: 1;
    width: 48%;
  }
}

@media only screen and (min-width: 720px) {
  .mobileWorkspaceSideBar {
    display: none;
  }
  .divider {
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsl(0, 0%, 30%);
    border-top: none;
    border-bottom: none;
    border-left: solid;
    border-right: solid;
    border-left-width: 1px;
    border-right-width: 1px;
    background: hsl(0, 0%, 95%);
    border-left-color: hsl(0, 0%, 90%);
    border-right-color: hsl(0, 0%, 90%);
    font-weight: 800;
    font-size: 1.2rem;
    box-sizing: border-box;
    outline: none;
    font-family: "Montserrat", sans-serif;
    z-index: 1;
    width: 2px;
  }

  .textInputContainer {
    max-width: 70%;
    min-width: 60%;
  }
}

.textInputContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.textInputBottomBar {
  box-sizing: border-box;
  height: 100%;
  font-size: 0.8em;
  font-weight: 600;
  color: #bec1bf;
  border-left: solid;
  border-left-width: 1px;
  border-left-color: #1d201f;
}

.textInputBottomBar .heading {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
}

.editor {
  flex: 1;
}

.editorSectionFull {
  flex: 1;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
}

.editorSectionHalf {
  height: 53%;
  display: flex;
  flex-direction: column;
}

.terminalContainerOpen {
  transition: all 0.3s ease-in-out;
  height: 47%;
}

.sideBar {
  flex: 1;
  max-width: 40%;
  min-width: 25%;
}

.terminalContainerOpen.isTerminalHighlighted {
  position: relative;
  z-index: 10;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.terminalContainerOpen.isTerminalHighlighted .textInputBottomBar {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: bold;
  color: #333;
}

.terminalContainerOpen.isTerminalHighlighted {
  position: relative;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}
