.containerContained {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: hsla(0, 0%, 99%, 70%);
  color: hsl(147, 49%, 44%);
  font-weight: 700;
  font-size: 1.5em;
}
.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: hsla(0, 0%, 99%, 70%);
  color: hsl(147, 49%, 44%);
  font-weight: 700;
  font-size: 1.5em;
}

.title {
  margin-top: 15px;
}
.svg {
  fill: hsl(147, 49%, 44%);
  animation: changeColor 5s infinite cubic-bezier(0.42, 0, 1, 1);
  transform-origin: 50% 50%;
}

@keyframes changeColor {
  0% {
    transform: rotate(0deg);
    fill: hsl(147, 49%, 44%);
  }
  100% {
    transform: rotate(360deg);
    fill: hsl(147, 49%, 44%);
  }
}
