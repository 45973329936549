.codeBlockPreview {
  flex: 1;
  font-size: 1rem;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #4b4e4c;
  border-radius: 10px;
  position: relative;
  background-color: #1b1d1c;
  padding: 5px;
  padding-top: 0;
}

.codeBlockPreviewHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: CircularStd, "Poppins", sans-serif;
  padding: 12px 11px;
}

.codeBlockPreviewHeaderTitle {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: 300;
}

.codeBlockPreviewHeaderButton {
  color: #0bda5e;
  cursor: pointer;
  padding: 0;
}

.codeBlockPreviewHeaderButton:hover {
  color: #29834d;
}

.codeBlockPreview code {
  font-family: "Courier Prime", monospace;
}

.highlighted {
  background-color: yellow !important;
  cursor: pointer;
}

.highlighted:hover {
  background-color: yellow !important;
  cursor: pointer;
  outline: 1px solid black;
}

.chatParent {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 20px;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.icons {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  font-size: 1.25rem;
}

.copyIcon {
  padding: 5px;
  border-radius: 2px;
  color: rgba(128, 128, 128, 0.45);
}

.copyIcon:hover {
  cursor: pointer;
  background-color: rgb(176, 174, 174);
  color: black;
}

.wandIcon {
  padding: 5px;
  border-radius: 2px;
  color: rgba(128, 128, 128, 0.45);
}

.wandIcon:hover {
  cursor: pointer;
  background-color: rgb(176, 174, 174);
  color: black;
}

.copyIcon:hover {
  cursor: pointer;
  background-color: rgb(176, 174, 174);
  color: black;
}

.textFieldPreview {
  width: 100%;
  font-size: 1rem;
  font-family: CircularStd, "Poppins", sans-serif;
  overflow-wrap: break-word;
  box-sizing: border-box;
  cursor: pointer;
}

.markdownPreview {
  width: 100%;
  font-size: 1rem;
  font-family: CircularStd, "Poppins", sans-serif;
  overflow-wrap: break-word;
  box-sizing: border-box;
  position: relative;
  color: #f2f2f2;
}

.textFieldPreview h1,
.markdownPreview h1 {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 18px 0;
}

.textFieldPreview h2,
.markdownPreview h2 {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 15px 0;
}

.textFieldPreview h3,
.markdownPreview h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 8px 0;
}

.textFieldPreview h4,
.markdownPreview h4 {
  font-size: 1rem;
  font-weight: 600;
  margin: 5px 0;
}

.textFieldPreview p,
.markdownPreview p {
  font-size: 1rem;
  margin: 0;
}

.textFieldPreview ul {
  list-style: disc inside;
  margin: 10px 0;
  font-size: 1rem;
}

.textFieldPreview ol {
  list-style: decimal inside;
  margin: 10px 0;
  font-size: 1rem;
}

.textFieldPreview code {
  background-color: #ccc;
  padding: 2px 5px;
  font-size: 1rem;
}

.textFieldPreview a {
  color: #39a76b;
}

.videoPreview {
  width: 100%;
  box-sizing: border-box;
}

.videoPreview iframe {
  width: 100%;
  height: 500px;
}

.practiceQuestionsPreview {
  width: 100%;
}

.practiceQuestionsHeading {
  font-size: 25px;
  font-weight: 700;
  margin: 15px 0;
}

.practiceQuestion {
  margin-bottom: 15px;
}

.continueGeneratingSectionChat {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 20px;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.clarificationSummary {
  cursor: pointer;
  background-color: #1b1d1c;
}
