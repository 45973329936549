
.popover {
    position: absolute;
    color: white; /* Text color */
    border-radius: 4px;
    z-index: 5;
  }
  
  .popoverResponse {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.853); /* Semi-translucent black background */
    color: white; /* Text color */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Drop shadow */
    backdrop-filter: blur(1px); /* Blurred background effect */
    z-index: 5;
    padding: 8px;
  }

  .question {
    background-color: rgba(0, 0, 0, 0.853); /* Semi-translucent black background */
    color: white; /* Text color */
    border-radius: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Drop shadow */
    backdrop-filter: blur(1px); /* Blurred background effect */
    z-index: 5;
    width: 100%;
    display: flex;
    padding: 10px;
  }

  .questionInput {
    width: 100%;
    background-color: transparent;
    color: white ;
    outline: none;
  }

  .popItems {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .arrowBtn {
    padding: 10px;    
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;
  }

  .arrowBtn:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Semi-translucent white background on hover */
  }



  .arrowBtnDisabled {
    padding: 10px;    
    color: #757575;
    cursor: default;
    background-color: transparent;
  }

  .arrowBtnDisabled:hover {
    background-color: none; /* Semi-translucent black background */
  }


  .menuBtns {
    background-color: rgba(0, 0, 0, 0.853); /* Semi-translucent black background */
    color: white; /* Text color */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Drop shadow */
    backdrop-filter: blur(1px); /* Blurred background effect */
    z-index: 5;
    width: fit-content;
  }
  
  /* Button styles */
  .menuBtns button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
    margin: 4px 0;
  }

  .menuBtns button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Semi-translucent white background on hover */

  }
  .button {
    display: flex;
    align-items: center;
  }

  /* Button hover effect */

  .clarifyIcon {
    background-color: white;
    color: #39a76b;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
  }
  

.loading {
  background-color: rgba(0, 0, 0, 0.853); /* Semi-translucent black background */
  width: fit-content;
  border-radius: 4px;
}
/* HTML: <div class="loader"></div> */
.loader {
    padding: 50px;
    width: 40px;
    aspect-ratio: 1;
     --c: linear-gradient(#ffffff 0 0);
     --m: radial-gradient(farthest-side,#ffffff 92%,#ffffff00);
    background: 
      var(--m) center               /12px 12px,
      var(--c) left 50% top    -20px/8px 16px, 
      var(--c) left 50% bottom -20px/8px 16px, 
      var(--c) top  50% left   -20px/16px 8px, 
      var(--c) top  50% right  -20px/16px 8px;
    background-repeat: no-repeat;
    animation: 
      l18-1 1.5s infinite,
      l18-2 1.5s infinite;
  }
  @keyframes l18-1 {
    30%,
    70% {background-position: 
          center,
          left 50% top    calc(50% - 8px),
          left 50% bottom calc(50% - 8px),
          top  50% left   calc(50% - 8px),
          top  50% right  calc(50% - 8px)}
  }
  @keyframes l18-2 {
    0%,40%   {transform: rotate(0)}
    60%,100% {transform: rotate(90deg)}
  }