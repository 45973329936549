.collapsible {
  border-radius: 4px;
  margin: 10px 0;
  max-width: 100%;
}

.collapsibleHeader {
  width: 100%;
  cursor: pointer;
  font-family: "CircularStd";
  font-size: 1rem;
  font-weight: 450;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #eff0f0;
}

.open {
  max-height: 70dvh !important;
  transition: max-height 0.3s ease-out;
}

.collapsibleContent {
  overflow: auto;
  transition: max-height 0.3s ease-out;
  max-height: 0;
  max-width: 100%;
}

.collapsibleIcon {
  margin-left: 10px;
  padding: 10px;
  font-size: 10px;
  background-color: #1d201f;
  transition: transform 0.3s ease-out;
  border-radius: 100%;
}

.collapsibleHeader[aria-expanded="true"] .collapsible-icon {
  transform: rotate(180deg); /* Optional rotation for the up arrow */
}
