.container {
  margin: 40px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gridTitle {
  font-size: 2em;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
  color: #0e2517;
}

.sectionText {
  font-size: 1.2em;
}

@media only screen and (min-width: 992px) {
  .mainWindow {
    position: relative;
    margin-left: 256px;
    padding: 20px;
  }

  .container {
    margin-left: 25px;
    margin-right: 25px;
  }
}
