.container {
  display: none;
  z-index: 100;
  position: fixed;
  top: 70px;
  width: 100%;
  height: 50%;
  background: hsl(0, 0%, 99%);
}

.active {
  color: hsl(147, 52%, 46%);
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    display: block;
    position: fixed;
    width: 256px;
    height: 100%;
    background: #f8fafb;
    box-sizing: border-box;
  }
  .buttonContainer {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    box-shadow: 0px 1px 10px 0px #1115100d;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 500;
  }

  .button {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 20px 10px;
    border-bottom: 1px dashed #eaebeb;
    outline: none;
    line-height: 16px;
  }

  .button:first-of-type {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  .button:last-of-type {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-bottom: 0px;
  }

  .buttonIcon {
    color: hsl(0, 0%, 60%);
    font-size: 1rem;
  }

  .button .buttonText {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    color: hsl(0, 0%, 50%);
  }

  .active .buttonText {
    color: hsl(147, 52%, 46%);
  }

  .active .buttonIcon {
    color: hsl(147, 52%, 46%);
  }

  .button:hover {
    background: hsl(0, 0%, 88%);
    cursor: pointer;
  }

  .button:hover .buttonText {
    color: hsl(147, 52%, 46%);
  }

  .button:hover i {
    color: hsl(147, 52%, 46%);
  }
}
