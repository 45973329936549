html,
body,
#root,
.App {
  height: 100%;
  font-family: CircularStd;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-Book.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-Book.otf") format("opentype");
  font-weight: 450;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-BookItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-Black.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "CircularStd";
  src:
    local("CircularStd"),
    url("./fonts/Circular/CircularStd-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
