.container,
.desktopContainer {
  z-index: 8;
  position: fixed;
  background: hsl(0, 0%, 99%);
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.desktopContainer {
  display: flex;
}

.mobileContainer {
  display: none;
}

.titleBar {
  display: inline-block;
  width: 100px;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.titleBar:hover {
  cursor: pointer;
}

.titleBarTransparent {
  background: transparent;
  border-bottom: 1px solid gainsboro;
}

.rightNav {
  border-bottom: 1px solid gainsboro;
  display: flex;
  flex: 1;
  height: 100%;
}

.leftBar {
  margin-left: 30px;
  color: hsl(0, 0%, 30%);
  display: flex;
}

.welcome {
  font-size: 23px;
  font-weight: 500;
  color: #0e2517;
  align-self: center;
}

.gradYear {
  font-weight: 600;
}

@media (max-width: 1000px) {
  .container {
    border-bottom: 1px solid gainsboro;
  }

  .menuBars {
    background-color: #29834d;
    padding: 10px;
    color: white;
    border-radius: 6px;
  }

  .rightBar {
    gap: 12px;
  }

  .desktopContainer {
    display: none;
  }

  .notifications {
    border-width: 0px, 0px, 0px, 0px;
    border: 1px solid #dfe2e1;
  }

  .mobileContainer {
    position: fixed;
    background: hsl(0, 0%, 99%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .mobileMenuButton {
    font-size: 1.25rem;
    color: #333;
  }

  .mobileMenuOverlay {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100vh;
    height: 100dvh;
  }

  .mobileMenu {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom: 1px solid gainsboro;
  }

  .mobileMenu a {
    margin: 10px 0;
    color: hsl(147, 49%, 44%);
    text-decoration: none;
    font-weight: 500;
  }

  .mobileMenu .logoutButton {
    color: red;
  }
}

@media only screen and (min-width: 1025px) {
  .titleBarTransparent {
    background: transparent;
    border-bottom: 1px solid gainsboro;
  }
}
