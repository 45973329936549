.card {
  width: 300px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(158, 235, 235, 0.75) 0%,
    rgba(213, 234, 153, 0.75) 47%,
    rgba(234, 217, 153, 0.75) 100%
  );
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.cardTitle {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: black;
}

.cardPrice {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold;
  color: #39a76b;
}

.cardFeatures {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.cardFeatures li {
  margin: 10px 0;
  font-size: 1.1em;
  color: black;
}

.cardButton {
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  background-color: #39a76b;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cardButton:hover {
  background-color: #287a4d;
}
