body {
  overflow-y: hidden;
}

.noSelect {
  user-select: none;
}

button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
}

input {
  border: 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.containerMobile {
  display: none;
}

.containerMobile {
  display: none;
}

.hidden {
  display: none;
}

.mainWindow {
  display: flex;
  justify-content: space-between;
  background-color: #161817;
  align-items: flex-start;
  margin-top: 70px;
  height: calc(100vh - 70px);
  height: calc(100dvh - 70px);
}

.contentEditor,
.codeEditor {
  background-color: #161817;
  height: 100%;
  overflow-y: auto;
  transition: width 0.3s linear;
}

.contentEditor {
  width: 50%;
}

.codeEditor {
  flex: 1;
}

.contentEditor {
  position: relative;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5px;
  font-weight: 800;
  font-size: 1.2rem;
  cursor: col-resize;
  height: calc(100vh - 70px);
  height: calc(100dvh - 70px);
  border-top: none;
  border-bottom: none;
  background-color: #0f1010;
}

.divider:hover,
.divider:active {
  background: #2c2f2f;
}

.workspaceDivider {
  background: #1d201f;
}

.codeEditor {
  flex: 1;
}

@media only screen and (max-width: 1025px) {
  .container {
    display: none;
  }

  .containerMobile {
    display: flex;
    flex-direction: column;
  }

  .contentEditor,
  .codeEditor {
    width: 100%;
    /* transition: none; */
  }

  .mobileSwitchButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1rem;
    bottom: 100px;
    right: 0px;
    height: 50px;
    width: 50px;
    background: #39a76b;
    z-index: 3;
  }
}
