.lessonCard {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-sizing: border-box;
  color: #4d4d4d;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #eaebeb;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease;
}

.lessonCard:hover {
  background: hsl(0, 0%, 98%);
}

.innerProgressContainer {
  position: absolute;
  top: -25px;
  background-color: white;
  border-radius: 100%;
  height: fit-content;
  width: fit-content;
  box-shadow: 0px 4px 20px 0px #0e25171a;
}

.title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  color: #0e2517;
  height: 3rem; /* Adjust based on line-height and font-size */
  max-height: 4.5rem;
  overflow: hidden;
}

.author {
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  color: #707572;
}

.learnerText {
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #707572;
}

.lessonDetails {
  flex: 1;
  padding: 30px 15px 20px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.imageContainer {
  height: 160px;
}

.imgElem {
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.imageIcons {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  margin: 18px 0;
}

.imageContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.leftContainer {
  display: flex;
  margin-left: 11px;
  gap: 8px;
}

.editIcon,
.lessonDeleteIcon {
  padding: 0 10px;
  background-color: white;
  border-radius: 100%;
  transition: color 0.1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editIcon:hover {
  color: #29834d;
}

.lessonDeleteIcon:hover {
  color: #ff0303;
}

.rightIconsContainer {
  margin-right: 11px;
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
}

.bookmark {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  gap: 7px;
  background-color: white;
  border-radius: 15px;
  align-items: center;
}

.bookmark:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  .bookmarkIcon {
    color: #29834d;
  }
}

.bookmarkIcon {
  margin-left: 0px;
}

.share {
  padding: 8px;
  background-color: white;
  border-radius: 100%;
}

.share:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.mobileBookmark {
  display: none;
}

.mobileShare {
  display: none;
}

.lineDivider {
  width: 100%;
  margin-top: 20px;
  height: 1px;
  border-radius: 2px;
  border: 3px solid rgba(250, 250, 250, 1);
  box-shadow: 0px 1px 1px 0px rgba(112, 117, 114, 0.08) inset;
}

.imageStack {
  display: flex;
  justify-content: flex-start;
}

.stackImage {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  object-fit: cover;
  border: 2px solid white;
  margin-left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.stackImage:nth-child(1) {
  margin-left: 0;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.learnersContainer {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconWithText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.iconWithText .textBoxContainer {
  background: linear-gradient(180deg, #66cc8d 0%, #55dddd 66.25%);
  border-radius: 20px;
  padding: 1px;
}

.iconWithText .textBox {
  color: #0e2517;
  white-space: nowrap;
  font-size: 12px;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: #ecf9f1;
}

.genericImage {
  background-color: #29834d;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 200;
  border-radius: inherit;
}

.profileImage {
  border: 4px solid #ffffff;
  border-radius: 6px;
  position: relative;
  height: 30px;
  width: 30px;

  /* Mobile only */
  margin-left: auto;
  margin-right: auto;
}
