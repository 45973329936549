.container {
  margin: 40px 10px;
}

.gridTitle {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #0e2517;
}

@media only screen and (min-width: 992px) {
  .container {
    margin-left: 25px;
    margin-right: 25px;
  }
}
