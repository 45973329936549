.sectionPreviewWrapper {
  display: flex;
  margin-bottom: 20px;
}

.sectionPreviewIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  padding: 0 15px;
  box-sizing: border-box;
}

.sectionPreviewIcons .icon {
  font-size: 1.125rem;
  margin: 8px 0;
  padding: 0;
  color: black;
}

.sectionPreviewIcons .sectionDeleteIcon {
  color: #ff0303;
}
