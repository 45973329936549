.container {
  padding: 0.6rem 1rem;
  margin-left: auto;
  margin-right: auto;
  background: green;
  color: white;
  border-radius: 3rem;
  font-family: "CircularStd";
  box-sizing: border-box;
  margin-right: 1rem;
}