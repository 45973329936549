.container {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px;
}

.lessons {
  margin-left: auto;
  width: 100%;
  margin-right: 30px;
}

.container {
  margin-bottom: 30px;
  border-radius: 0px 0px 0px 0px;
  color: rgb(77, 77, 77);
}

.header {
  height: 240px;
  background: linear-gradient(180deg, #9eebeb 0%, #d5ea99 47%, #ead999 100%);
}

.profileNav {
  display: flex;
  justify-content: space-between;
  margin-right: 30pxa;
}

.errorComponentContainer {
  position: absolute;
  right: 0;
  z-index: 20;
}

.userLessonNav {
  border-radius: 100px;
  height: fit-content;
  align-self: center;
  background-color: white;
  box-shadow: 0px 2px 20px 0px #0e25171a;
  gap: 24px;
  justify-content: space-between;
  display: flex;
  padding: 20px 30px;
  width: 100%;
  margin-bottom: 60px;
}

.right {
  display: flex;
  width: 100%;
}

.myLessonsTab {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  color: #979b99;
  cursor: pointer;
}

.searchIcon {
  color: #cbcdcc;
  margin-left: 20px;
  margin-right: 10px;
  align-self: center;
}

.filterIcon {
  background-color: white;
  border-radius: 100%;
  padding: 5px;
  margin-right: 5px;
  align-self: center;
  box-shadow: 0px 4px 20px 0px #0e25171a;
}

.searchContainer {
  display: flex;
  border-radius: 100px;
  flex: 1;
  height: 40px;
  color: #29834d;
}

.searchContainer,
input {
  background: #fafafa;
  border: 1px solid #e9edeb;
}
.searchContainer input:focus {
  outline: none;
  border-color: transparent;
}

input {
  flex: 1;
  border: none;
}

.createdLessonsTab {
  font-size: 1rem;
  font-weight: 500;
  color: #979b99;
  cursor: pointer;
}

.active {
  color: #29834d;
}

.left {
  display: none;
}

.paginationButtons {
  display: flex;
  gap: 10px;
  margin-top: 50px;
  /* justify-content: center; */
}

.previousButton, .nextButton {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  color: #29834d;
  cursor: pointer;
  border: 1px solid #29834d;
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (min-width: 770px) {
  .left {
    display: flex;
    gap: 24px;
    align-self: center;
  }

  .right {
    display: flex;
    width: 45%;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    margin-top: -41px;
    margin-left: 40px;
    margin-right: 40px;
  }
}
