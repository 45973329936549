.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
}

.bgStandard {
  background-color: white;
}

.error {
  margin-bottom: 20px;
}

.spacing {
  height: 20px;
}

.innerSignupDiv {
  padding-left: 0px;
  background-color: white;
  color: black;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginButtonLeadingText {
  display: none;
}

.loginButton {
  height: 40px;
  padding: 10px 15px 10px 15px;
  background: transparent;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: gainsboro;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 15px;
  text-decoration: none;
  color: rgb(31, 31, 31);
}

.loginButton:visited {
  color: rgb(31, 31, 31);
}

@media (min-width: 576px) {
  .loginButtonLeadingText {
    display: inline-block;
  }
}

@media only screen and (min-width: 1025px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    width: 100dvw;
  }

  .bgStandard {
    background-color: white;
  }

  .spacing {
    height: 20px;
  }

  .loginButton {
    height: 40px;
    padding: 10px 15px 10px 15px;
    font-family: "Montserrat", sans-serif;
    background: transparent;
    border: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: gainsboro;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 15px;
    text-decoration: none;
  }

  .loginButton:visited {
    color: rgb(31, 31, 31);
  }
}
