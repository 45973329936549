.container {
  width: 100%;
  max-width: 700px;
  height: 65vh;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.modalParent {
  align-content: center;
}

.confirmationContainer {
  width: fit-content;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.contentDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  box-sizing: border-box;
  overflow: auto;
  background: white;
  border-radius: 20px;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(158, 235, 235, 0.6) -4.58%,
    rgba(213, 234, 153, 0.6) 5.06%,
    white 15%
  );
}

.modalCloseButton {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 1.5rem;
  color: #0e2517;
}

.title {
  color: rgba(11, 30, 18, 1);
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 10px;
}

.subscriptionPlan {
  font-weight: bold;
  color: #39a76b;
}

.features {
  display: flex;
  flex-direction: column;
}

.subscriptionFeatures {
  font-size: 0.8rem;
  color: hsl(0, 0%, 40%);
  font-weight: 500;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.subscriptionFeaturesTitle {
  font-size: 1rem;
  color: hsl(0, 0%, 40%);
}

.subscriptionFeatures li {
  text-indent: 2em;
}

/* .subscriptionFeatures li::before {
  content: "\2713\0020";
} */

.contentDiv p {
  font-size: 1rem;
  color: hsl(0, 0%, 40%);
  font-weight: 500;
  margin: 0;
  margin-bottom: 40px;
}

.content {
  font-size: 0.9rem;
  font-weight: 450;
  color: hsl(0, 0%, 40%);
  line-height: 1.7;
  margin-bottom: 25px;
}

.cancelButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: solid;
  border-width: 1px;
  border-radius: 30px;
  margin-bottom: 15px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-sizing: border-box;
  background: rgb(191, 14, 14);
}

.subscribeButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: solid;
  border-width: 1px;
  border-radius: 30px;
  margin-bottom: 15px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-sizing: border-box;
  background: #29834d;
}

.subscribeButton:hover {
  cursor: pointer;
}

.cancelButton:hover {
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 10px;
}

.cancelFinalButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 48%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: solid;
  border-width: 1px;
  border-radius: 30px;
  margin-bottom: 15px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-sizing: border-box;
  background: rgb(191, 14, 14);
}

.undoFinalButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 48%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: solid;
  border-width: 1px;
  border-radius: 30px;
  margin-bottom: 15px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 700;
  color: black;
  box-sizing: border-box;
  background: white;
}

.undoFinalButton:disabled,
.cancelFinalButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (min-width: 768px) {
  .contentDiv {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .modalCloseButton {
    display: none;
  }
}
