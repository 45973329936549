.container {
  margin: 40px 10px;
}

.gridTitle {
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 10px;
  color: #0e2517;
}

.chartTitle {
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 5px;
  color: #0e2517;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.analyticsCards {
  display: flex;
  flex-direction: row;
}
.analyticsColumn {
  flex: 1;
  box-sizing: border-box;
  margin-right: 15px;
  margin-left: 15px;
}
.analyticsColumnMajor {
  flex: 2;
  box-sizing: border-box;
  margin-right: 15px;
}
.analyticsCard {
  flex: 1;
  box-sizing: border-box;
  border-radius: 10px;
  padding-bottom: 50px;
  height: 500px;
  background: #f0f0f0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
/* Bug in library forces us to do this https://github.com/recharts/recharts/issues/172 */
.analyticsTimeSeries {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}
.analyticsPiechart {
  display: flex;
  flex-direction: row;
}

.toggleButtonContainer {
  flex: 1;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
  margin-bottom: 15px;
}

.graphToggleButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  flex: 1 0 34%;
  margin: 15px;
  border: none;
  border-radius: 10px;
  color: darkgray;
  max-width: calc(33% - 30px);
}

.graphToggleButton:hover {
  cursor: pointer;
  color: hsl(147, 52%, 46%);
}

.toggleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  border-radius: 10px;
  margin-left: 10px;
}

.toggleIcon i {
  font-size: 40px;
}
.toggleText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  box-sizing: border-box;
  flex: 1;
}

.activeGraphToggleButton {
  color: hsl(147, 52%, 46%);
}
.toggleTextTopMetric {
  font-size: 2em;
  font-weight: 600;
}
.toggleTextDescription {
  font-size: 1.4em;
}
.rightIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  border-radius: 10px;
  margin-left: 10px;
}

.rightIcon i {
  font-size: 40px;
}

.warning {
  color: hsl(0, 0%, 30%);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: 600;
}

.warning i {
  font-size: 40px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 992px) {
  .container {
    margin-left: 25px;
    margin-right: 25px;
  }
}
