.form {
  width: 92%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  /* border: solid 1px #e0e0e0; */
}

.input {
  width: 100%;
}

.titleComponent {
  font-size: 1.3rem;
  font-weight: 500;
  padding-bottom: 10px;
}

.textComponent {
  font-size: 0.8rem;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  color: #666;
}

.input::placeholder {
  color: black;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black;
}

.submit {
  background-color: hsl(147, 49%, 44%);
  height: 50px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: solid;
  border-color: hsl(147, 49%, 44%);
  border-width: 1px;
  border-radius: 7px;
  margin-bottom: 15px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-sizing: border-box;
}

.spacing {
  height: 20px;
}

@media (min-width: 576px) {
  .form {
    width: 40%;
  }

  .titleComponent {
    font-size: 2rem;
  }

  .textComponent {
    font-size: 1rem;
    width: 60%;
  }
}

@media only screen and (min-width: 1025px) {
  .input {
    width: 70%;
  }
  .submit {
    width: 70%;
  }
}
