.container {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background: linear-gradient(180deg, #f2f8f5 14.06%, #f8fbfa 77.63%);
  height: 100dvh;
}

.mainWindow {
  border-top: 1px solid gainsboro;
  margin-left: 0;
  margin-top: 70px;
  box-sizing: border-box;
  /* add top padding to calc */
  overflow-y: auto;
  max-height: calc(100% - 70px);
  height: calc(100% - 70px);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.copyright {
  text-align: center;
  opacity: 0.5;
  font-size: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 992px) {
  .mainWindow {
    margin-left: 256px;
  }
}
