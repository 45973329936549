.container {
  margin: 40px auto 40px auto;
  display: flex;
  gap: 12px;
  width: 60%;
}

.examples {
  display: flex;
  gap: 22px;
  margin-top: 20px;
}

.aiContainer {
  height: 100%;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  flex: 2;
}

.aiBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.promptInput {
  background-color: white;
  border: 1px solid #dcdcdc;
  margin: 1.2rem 0 0 0;
}

.promptInput > textarea {
  padding: 17px 20px;
  font-size: 0.9rem;
  color: black;
}

.promptInput > button {
  margin-right: 5px;
  padding: 10px 1rem;
}

.todayText {
  display: block;
  color: #29834D;
}

.exampleCard {
  background-color: #fff;
  border: 4px solid #f8fbfa;
  box-shadow: 0px 4px 6px 0px #deede640;
  flex: 1;
}

.gridTitle {
  margin-top: 10px;
  font-family: "CircularStd";
  font-size: 32px;
  font-weight: 500;
  text-align: left;
}

.lessonsContainer {
  flex: 1.5;
  border: 1px solid #e4e7e6;
  border-radius: 16px;
}

.containerHeader {
  margin: 30px;
  display: flex;
  justify-content: space-between;
}

.headerHeading {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.125rem;
  font-weight: 450;
  line-height: 23px;
  color: #0e2517;
}

.lessonCount {
  border-radius: 20px;
  padding: 4px 12px;
  color: #26a69e;
  box-shadow: 0px 4px 20px 0px #0e25171a;
  font-size: 14px;
  font-weight: 450;
}

.headerSubHeading {
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
  text-align: left;
  color: #707572;
  margin-top: 5px;
}

.seeAllContainer {
  display: flex;
}

.seeAllButton {
  text-align: center;
  background: #ecf9f1;
  color: #29834d;
  display: flex;
  align-self: center;
  border-radius: 30px;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.lessonCardsContainer {
  position: relative;
}

.lessonCards {
  position: relative;
}

.arrowsContainer {
  display: flex;
  gap: 80px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.arrowIcon {
  background-color: #eff0f0;
  padding: 12px;
  font-size: 1.125rem;
  border-radius: 100%;
  cursor: pointer;
}

.disabled {
  cursor: default;
  color: #afb6b2;
}

.effectDiv {
  position: relative;
  display: flex;
}

.cardOne,
.cardTwo {
  position: absolute;
  width: 100%;
}

.cardOne {
  top: 7px;
  z-index: 4;
}

.cardTwo {
  top: 14px;
  z-index: 3;
}

.placeholderCard {
  display: flex;
  background: #fff;
  border: 1px solid #eff0f0;
  margin: 0 30px;
  padding: 8px 20px;
  border-radius: 3rem;
  color: #4d4d4d;
  height: 100%;
  box-shadow: 0px 6px 24px 0px #818b981f;
}

.titlePH {
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  color: transparent;
}

.lessonTextPH {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  color: transparent;
}

.authorPH {
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
  line-height: 15px;
}

.furtherQ {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.furtherQSub {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}
.question {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 5px;
}

.question input {
  padding: 5px;
  width: 90%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.question input:focus {
  outline: none;
}

.furtherSub {
  background-color: #29834d;
  color: white;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 992px) {
  .container {
    margin: 0;
    margin-bottom: 40px;
    width: 100%;
  }

  .aiContainer {
    border-radius: 0;
    width: 100%;
  }

  .examples {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .todayText {
    display: inline;
    color: #29834D;
  }

  .containerHeader {
    margin-left: 20px;
    margin-right: 20px;
  }

  .lessonsContainer {
    margin: 20px 10px;
    border: 1px solid #e4e7e6;
    border-radius: 16px;
  }

  .placeholderCard {
    margin-right: 10px;
    margin-left: 10px;
  }
}
