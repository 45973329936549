body {
  overflow-y: hidden;
}

button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
}

input {
  border: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.containerMobile {
  display: none;
}

.containerMobile {
  display: none;
}

.pageBuilderContainer {
  flex: 1;
  overflow: auto;
}

.mainWindow {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  align-items: flex-start;
  margin-top: 70px;
}

.contentEditor,
.codeEditor {
  width: 49%;
  background-color: #fcfcfc;
  height: calc(100vh - 70px);
  height: calc(100dvh - 70px);
  max-height: calc(100vh - 70px);
  max-height: calc(100dvh - 70px);
  overflow-y: auto;
}

.contentEditor {
  position: relative;
  min-width: 40%;
  max-width: 100%;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  color: #666666;
  font-weight: 800;
  font-size: 1.2rem;
  cursor: col-resize;
  height: calc(100vh - 70px);
  border: 1px solid #e6e6e6;
  border-top: none;
  border-bottom: none;
}

.divider:hover {
  background: #e6e6e6;
}

.divider:active {
  background: #e6e6e6;
}

.codeEditor {
  flex: 1;
}

.pageTitle {
  margin: 36px 5%;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  width: 90%;
}

.pageTitle input {
  color: #39a76b;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
}

.pageTitle input::placeholder {
  color: #39a76b;
}

.pageTitle input:focus {
  outline: 0;
}

.contentArea {
  width: 90%;
  margin: 0 auto;
}

.contentTypePopOverContainer {
  position: absolute;
  right: 20px;
  width: 60%;
  max-width: 500px;
  top: 37%;
  background-color: white;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #eee;
}

.contentTypePopOverIcon {
  position: absolute;
  top: 25%;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
}

.contentTypeIcons {
  width: 90%;
  display: flex;
  flex-shrink: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}

.contentTypeIcons .icon {
  font-size: 25px;
  background-color: rgba(89, 164, 112, 0.09);
  color: #39a76b;
  padding: 20px 8%;
  border-radius: 10px;
}

.contentTypeIcons .activeIcon {
  color: white;
  background-color: #39a76b;
}

.richTextEditorWrapper,
.richTextPreviewWrapper {
  display: flex;
  width: 90%;
  margin: 0 5%;
}

.richTextEditorWrapper {
  position: relative;
}

.contentEditorIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentEditorIcons .icon {
  margin: 8px 0;
}

.contentEditorIcons .contentDeleteIcon {
  color: #ff0303;
}

.addContent {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  padding-bottom: 60px;
}

.addContent .icon {
  font-size: 48px;
  color: #666666;
}

.savePage {
  position: fixed;
  bottom: 80px;
  left: 40px;
}

.savePage .savePageButton {
  border-radius: 100%;
  background-color: #39a76b;
  color: #fcfcfc;
  padding: 15px 20px;
  font-size: 30px;
}

.pageMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1B1D1C;
  border-top: 1px solid #1D201F;
  width: inherit;
  min-width: 40%;
  max-width: 100%;
  height: 70px;
}

.pagesNavigation {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #BEC1BF;
}

.pageNavigationArrow {
  color: #BEC1BF;
  padding: 0;
  font-size: 1.2rem;
  margin-top: 2px;
}

.addPageButton {
  background-color: #29834D;
  color: white;
  border-radius: 30px;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.5rem 2.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.icon {
  font-size: 1.125rem;
  color: #BEC1BF;
}

.errorContainer {
  position: absolute;
  width: 80%;
  max-width: 600px;
  top: 20px;
  right: 20px;
}

@media only screen and (max-width: 600px) {
  .contentTypePopOverContainer {
    width: 95%;
  }

  .savePage {
    left: 20px;
  }
}

@media only screen and (max-width: 1025px) {
  .container {
    display: none;
  }

  .containerMobile {
    display: flex;
    flex-direction: column;
  }

  .contentEditor,
  .codeEditor {
    width: 100%;
  }

  .mobileSwitchButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1rem;
    bottom: 100px;
    right: 0px;
    height: 50px;
    width: 50px;
    background: #39a76b;
    z-index: 1;
  }

  .pageMenu {
    width: 100%;
  }

  .pageMenu .pagesNavigation {
    padding-left: 10px;
  }
}
