.nodeWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 32px;
  padding-inline-end: 10px;
  padding-inline-start: 20px;
  border-radius: 0px;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  z-index: 3;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8em;
  font-weight: 500;
  color: #dfe2e0;
}

.button {
  padding: 5px;
  color: #dfe2e0;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.directoryOptionIcon {
  padding: 0px;
  background-color: #1d201f;
  border-radius: 100%;
  border: 1px solid #222524;
}

.directoryOptionIcon:hover {
  background-color: #1d201f;
  cursor: pointer;
}

.fileNameInput {
  width: 100%;
  border-radius: 2px;
  margin-top: 0;
  height: auto;
  text-align: left;
  font-size: 0.8em;
  font-weight: 500;
  white-space: nowrap;
  padding-left: 2px;
  padding: 0 0 0 2px;
  color: white;
}

.nodeWrapper:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.nodeWrapper .expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  width: 24px;
  transform: rotate(-90deg);
  justify-content: flex-end;
}

.leftIcons {
  display: flex;
  align-items: center;
}

.rightIcons {
  display: flex;
}

.nodeWrapper .expandIconWrapper.isOpen {
  transform: rotate(0deg);
  justify-content: initial;
}

.nodeActive {
  background-color: rgba(0, 0, 0, 0.28) !important;
}
.nodeWrapper .expandIconWrapper.isOpen svg path {
  fill: #dfe2e0;
}

.nodeWrapper .labelGridItem {
  padding-inline-start: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pipeY {
  position: absolute;
  border-left: 2px solid #e7e7e7;
  left: 2px;
  top: -7px;
}

.pipeX {
  position: absolute;
  left: 2px;
  top: 15px;
  height: 2px;
  background-color: #e7e7e7;
  z-index: -1;
}

.wrapper {
  font-family: sans-serif;
}

.treeRoot {
  list-style-type: none;
  padding-inline-start: 0px;
  position: relative;
}

.treeRoot ul {
  list-style-type: none;
  padding-inline-start: 0px;
  position: relative;
  padding-bottom: 5px;
  padding: 0px !important;
  margin: 0px !important;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.treeRoot > li:after {
  display: none;
}

.wrapper .draggingSource {
  opacity: 0.3;
}

.wrapper .placeholder {
  position: relative;
}

.wrapper > ul > li > .nodeWrapper > div.pipeY {
  display: none;
}

.wrapper li:has(> .dropTarget) {
  outline: 3px solid #e8f0fe;
  border-radius: 4px;
}

/* WORKSPACE STYLES */
.workspaceRightIcons {
  display: flex;
  gap: 6px;
}

.sidebar {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.sidebarContainer {
  flex: 1;
}

.switch {
  color: #39a76b;
}

.saveDirectory {
  display: flex;
  gap: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 0.9em;
  padding: auto 20px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 5px 0px 5px 5px;
  font-family: CircularStd;
  font-size: 16px;
  font-weight: 450;
  height: 30px;
  margin: 10px 8px;
  /* background-color: rgb(248, 248, 248); */
  color: #dfe2e0;
}

.noNodes {
  margin-top: 70px;
  font-size: 0.8em;
  font-weight: 600;
  text-align: center;
  color: #dfe2e0;
  font-family: CircularStd;
  font-size: 14px;
  font-weight: 450;
  line-height: 17.71px;
  text-align: center;
}

.directoryTypes {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 18px;
}

.directoryTypeButton {
  background-color: #161817;
  padding: 7px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-family: CircularStd;
  font-size: 12px;
  font-weight: 450;
  line-height: 15.18px;
  text-align: left;
  color: #0bda5e;
}

.directoryTypeButton:hover {
  background-color: #1d201f;
}

.noNodeIcon {
  height: 10px;
}
