body {
  overflow-y: hidden;
}

input {
  border: 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.containerMobile {
  display: none;
}

.containerMobile {
  display: none;
}

.hidden {
  display: none;
}

.mainWindow {
  display: flex;
  justify-content: space-between;
  background-color: #161817;
  align-items: flex-start;
  margin-top: 70px;
  height: calc(100vh - 70px);
  height: calc(100dvh - 70px);
}

.contentEditor{
  background-color: #161817;
  height: 100%;
  overflow-y: auto;
  transition: width 0.3s linear;
  width: 100%;
  position: relative;
}

.clarifications {
  width: 10%;
  height: 100%;
  border-right: 1px solid #313534;
  box-sizing: border-box;
  padding-right: 1rem;
}

@media only screen and (max-width: 1025px) {
  .container {
    display: none;
  }

  .containerMobile {
    display: flex;
    flex-direction: column;
  }

  .contentEditor{
    width: 100%;
    /* transition: none; */
  }

  .mobileSwitchButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1rem;
    bottom: 100px;
    right: 0px;
    height: 50px;
    width: 50px;
    background: #39a76b;
    z-index: 3;
  }
}
