.feedback {
  padding: 10px;
  box-sizing: border-box;
  width: 150px;
  background: transparent;
  font-weight: 700;
  color: hsl(147, 52%, 24%);
  border-radius: 0.3rem;
  border-color: hsl(147, 52%, 24%);
  font-size: 0.9rem;
  border-width: 1px;
  text-decoration: none;
  border: solid;
  text-align: center;
}

.feedback:visited {
  color: hsl(147, 52%, 24%);
  border-color: hsl(147, 52%, 24%);
}
