.container {
  border-left: 0.5px solid #1d201f;
  border-right: 0.5px solid #1d201f;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  color: #eff0f0;
  padding: 10px;
}

.clarificationNew {
  background-color: #1d201f;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
  margin-top: 28px;
  border-radius: 50px;
  text-align: center;
  font-family: "CircularStd";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: 12.65px;
  margin-bottom: 20px;
}

.clarificationTitle {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 20px;
  border-bottom: 2px solid #dfe2e1;
}

.activeChat {
  background-color: #1d201f;
  color: #eaebeb !important;
}

.clarifications {
  overflow: auto;
}

.clarification {
  padding: 15px;
  cursor: pointer;
  color: #8a8f8c;
  font-family: "CircularStd";
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 16.93px;
  text-align: left;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.clarification:hover {
  background-color: #1d201f;
}

.workspaceClarifications {
  width: 100%;
}
