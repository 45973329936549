.submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-color: hsl(147, 49%, 44%);
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: solid;
  border-color: hsl(147, 49%, 44%);
  border-width: 1px;
  border-radius: 7px;
  margin-bottom: 15px;
  padding: 15px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
}
